import React from 'react';
import Layout from '../../../components/Layout';
import Section from '../../../components/Section';
import { Container, Row, Column } from '../../../components/Grid';
import Image from '../../../components/Image';
import Subfooter from '../../../components/Subfooter';
import Hero from '../../../components/Hero';
import CourseUnitCard from '../../../components/CourseUnitCard';

const heroMultiImages = [
  {
    image: '/img-lead-agenviro.png',
    title: 'Ag/Environmental',
    subtext: 'CTE Curriculum | 9-Week Units',
    text:
      'Investigate the amazing ways that living organisms can be harnessed to improve agriculture processes and redefine a healthier relationship with the natural environment.',
  },
  {
    image: '/img-lead-agenviro-top-right.png',
  },
  {
    image: '/img-lead-agenviro-bottom-right.png',
  },
];

const IndexPage = () => (
  <Layout title="Ag-Environmental">
    <Hero className="pb-0" mediaSource={heroMultiImages} color={'green'} />
    <Section className="living-earth__at-a-glance-section pt-0">
      <Container fullWidth className="living-earth__at-a-glance-container">
        <Row>
          <Column size={12}>
            <h5 className="mb-0">AG/Environmental COURSE</h5>
            <h3 className="living-earth__at-a-glance-header">At a Glance</h3>
          </Column>
        </Row>
        <Row>
          <Column medium={3} xsmall={6}>
            <Image filename="icon-instructional-support.svg" />
            <h4 className="living-earth__at-a-glance-section-header mt-0">
              EDUCATOR PREP
            </h4>
            <p className="mr-3">
              Each unit includes pacing guide, standards alignment,
              framing/instructional support and lab material needs.
            </p>
          </Column>
          <Column medium={3} xsmall={6}>
            <Image filename="icon-course-outline.svg" />
            <h4 className="living-earth__at-a-glance-section-header mt-0">
              COURSE OUTLINE
            </h4>
            <ul className="living-earth__at-a-glance-li">
              <li>1 School Year</li>
              <li>9 Week Units</li>
              <li>Quarter-based Units</li>
            </ul>
          </Column>
          <Column medium={3} xsmall={6}>
            <Image filename="icon-topics-covered.svg" />
            <h4 className="living-earth__at-a-glance-section-header mt-0">
              TOPICS COVERED
            </h4>
            <ul className="living-earth__at-a-glance-li">
              <li>Bacteria</li>
              <li>Genetically Engineered Crops</li>
              <li>Drug Discovery</li>
              <li>DNA Technology</li>
            </ul>
          </Column>
          <Column medium={3} xsmall={6}>
            <Image filename="icon-student-outcomes.svg" />
            <h4 className="living-earth__at-a-glance-section-header mt-0">
              STUDENT OUTCOMES
            </h4>
            <ul className="living-earth__at-a-glance-li">
              <li>CTE Aligned Learning</li>
              <li>Lab Skills</li>
              <li>Project Based Experiences</li>
            </ul>
          </Column>
        </Row>
      </Container>
    </Section>
    <Section className="pb-0">
      <Container fullWidth>
        <Row className="pb-1">
          <Column size={8}>
            <h3 className="living-earth__getting-started-header mb-0.5">
              Getting Started
            </h3>
            <p className="living-earth__getting-started-desc">
              The units below are designed to be used sequentially over the
              course of the year with supporting lessons, labs, and student
              activities. Explore each unit for additional details to support
              curriculum during each quarter of the year.
            </p>
          </Column>
        </Row>
        <CourseUnitCard
          unitIdentifier="Unit 5"
          header="Solution Seeking Microbes"
          subheader="9 Weeks | Lesson Plans, Labs, Interactives and more"
          description="<strong>How can we harness the power of microbes to provide solutions to our local and global problems?</strong> 
                Design a solution to a real-world problem using a microbe superhero, then present it at a Micro-Con conference."
          img="thmb-agenviro-unit5@2x.jpg"
          to="/classroom-instruction/ag-environmental/unit-5"
        />
        <CourseUnitCard
          unitIdentifier="Unit 6"
          header="Alternative Proteins"
          subheader="9 Weeks | Lesson Plans, Labs, Interactives and more"
          description="<strong>How can struggles in our community be solved through genetic engineering (GE) technology?</strong> 
                Take on the role of a research team and explore the development of a novel GE product through the lenses of biotechnology, sustainability, and marketing."
          img="thmb-agenviro-unit6@2x.jpg"
          to="/classroom-instruction/ag-environmental/unit-6"
        />
        <CourseUnitCard
          unitIdentifier="Unit 7"
          header="Plant to Pharmaceutical"
          subheader="9 Weeks | Lesson Plans, Labs, Interactives and more"
          description="<strong>How are plant compounds identified, isolated, and manufactured to treat disease?</strong> 
                Examine medicinal plants to determine how nature can be harnessed to create hybrid compounds that improve human lives."
          img="thmb-agenviro-unit7@2x.jpg"
          to="/classroom-instruction/ag-environmental/unit-7"
        />
        <CourseUnitCard
          unitIdentifier="Unit 8"
          header="Community Science "
          subheader="9 Weeks | Lesson Plans, Labs, Interactives and more"
          description="<strong>How can we use DNA identification to solve problems in our lives, in our community, and on our planet?</strong> 
                Create a crowdfunding proposal to deploy a DNA collection kit that solves a community challenge."
          img="thmb-agenviro-unit8@2x.jpg"
          to="/classroom-instruction/ag-environmental/unit-8"
        />
      </Container>
    </Section>
    <Section className="pb-0">
      <Subfooter content="Biotech is revolutionizing every aspect of life, from advances in medical diagnostics and drug development to improvements in agricultural and food products." />
    </Section>
  </Layout>
);

export default IndexPage;
